export const accessCodeConstants = {

    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
    SUBMIT_FAILURE: 'SUBMIT_FAILURE',

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE'    
};